import React, { useState, useEffect, useRef } from "react";
import { Card, Image, Dropdown, Spinner } from "react-bootstrap";
import moment from "moment";
import { changeLanguage } from "../../store/actions/actions";
import { connect } from "react-redux";
import { languageText } from "./language.json";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router";
import firebase from "firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import * as EventStyle from "./event.module.css";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_BASE_URL, INVITE_PROCESS,PROD_HOSTED_BASE_URL } from "../../config";
import axios from "axios";
import { postData } from "../../utils";
import { split } from "lodash";

function EventFillDetails({ currentLanguage, changeLanguage }) {
  const { pathname } = useLocation();
  const history = useHistory();
  const { id } = useParams();

  const userDetails = firebase.auth().currentUser;
  if (!!userDetails) {
  } else {
    history.push("./login");
  }

  const [signing, setSigning] = useState(false);
  const [route, setRoute] = useState("");
  const [currentEvent, setCurrentEvent] = useState({});

  const [eventSnapshot, loadingEvent, errorEvent] = useCollection(
    firebase
      .firestore()
      .collection("events")
      .where("isDemo", "==", true)
      .where("slug", "==", `${id}`)
  );

  useEffect(() => {
    if (eventSnapshot) {
      setCurrentEvent({
        ...eventSnapshot?.docs[0]?.data(),
        id: eventSnapshot?.docs[0]?.id,
      });
    }
  }, [eventSnapshot, loadingEvent]);

  const pageRef = useRef(null);
  useEffect(() => {
    if (pathname.split("/")[3] && pathname.split("/")[3] === "register") {
      setRoute("register");
    }
    if (pathname.split("/")[3] && pathname.split("/")[3] === "login") {
      setRoute("login");
    }
  }, [pathname]);

  const redirectToDashboard = () => {
    if (pathname.split("/").length === 4) history.push("./dashboard");
    else history.push("../dashboard");
  };

  const continueWithEmail = (resetForm, values) => {
    setSigning(true);
    firebase
      .firestore()
      .collection(`/events/${currentEvent?.id}/users/`)
      .doc(userDetails?.uid)
      .set(
        {
          firstName: userDetails?.displayName,
          lastName: "",
          email: userDetails?.email,
          phone: values.phone,
          timestamp: new Date(),
          uid: userDetails?.uid,
          certificate: { willReceive: false },
          alreadyExists: false,
          custom_form_filled: false,
        },
        {
          merge: true,
        }
      )
      .then(() => {
        // alert(
        //   `Room invite code: ${currentEvent?.room_invite_code} & ${userDetails?.uid}`
        // );
        if (currentEvent?.room_invite_code) {
          const inviteProcessData = {
            invite_code: currentEvent?.room_invite_code,
            user_id: userDetails?.uid,
          };

          processRoomInvite(inviteProcessData);
        }

        toast.success("Registered successfully", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        resetForm();
        history.push("dashboard");
        setSigning(false);
      });
  };

  const continueWithNewEmail = async (resetForm, values) => {
    setSigning(true);

    try {

      // Check if the event is enabled for enrollment
      if(currentEvent && currentEvent.is_new_registration){
        toast.error("This event is no longer accepting new registrations.", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setSigning(false);
        return;
      }

      console.log("START: Completing enrollment of user");
      const response_enrollment = await postData(
        `${API_BASE_URL}/events/public/enroll-event`,
        {
          event_id: currentEvent?.id,
          user_id: userDetails?.uid,
          first_name: userDetails?.displayName?.split(" ")[0],
          last_name: userDetails?.displayName?.replace(
            `${userDetails?.displayName?.split(" ")[0]} `,
            ""
          ),
          phone_number: values?.phone ?? "",
          email: userDetails?.email,
        }
      );

      if (response_enrollment.status === 200) {
        toast.success("Registered successfully", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });

        resetForm();
        setSigning(false);
        redirectToDashboard();
      } else {
        toast.error(response_enrollment.message, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setSigning(false);
      }
      console.log("END: Completing enrollment of user");
    } catch (error) {
      console.log(`[Error] Completing enrollment of user`);
      setSigning(false);
    }
  };

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const RegisterVaildationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(1, "Please add valid name")
      .required("First name is required"),

    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    email: Yup.string().required("Required"),
  });

  const processRoomInvite = (inviteProcessData) => {
    axios
      .post(`https://api.igesia.com${INVITE_PROCESS}`, inviteProcessData)
      .then((response) => {
        console.log("Invite processing: ", response?.response?.data?.message);
      })
      .catch((error) => {
        console.error(
          "Error processing invite: ",
          error?.response?.data?.message
        );
      });
  };

  const convertSecondstoMilliSeconds = (seconds) => {
    return Math.floor(seconds * 1000);
  };

  return (
    <div className="container-fluid px-0 bg-graphite full-screen" ref={pageRef}>
      {/* Top navigation start */}
      <div className="container pt-3 pt-md-4 d-flex align-items-center justify-content-between">
        <div
        // onClick={() => history.push(`/`)}
        >
          <Image
            className={`rounded ${EventStyle.event_cover_logo}`}
            src={process.env.PUBLIC_URL + "/assets/Igesia-Logo.svg"}
          />
        </div>
        <Dropdown className="mr-3 small text-dark">
          <Dropdown.Toggle
            as="div"
            id="dropdown-basic"
            style={{ cursor: "pointer" }}
          >
            {currentLanguage === "EN" ? "EN" : "FR"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              className="small"
              active={currentLanguage === "EN"}
              onClick={() => changeLanguage("EN")}
            >
              {languageText[`${currentLanguage}`].english}
            </Dropdown.Item>
            {/* <Dropdown.Item
                            className="small"
                            active={currentLanguage === "FR"}
                            onClick={() => changeLanguage("FR")}
                        >
                            {languageText[`${currentLanguage}`].french}
                        </Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* Top navigation end */}

      {/* Event cover starts */}
      <div className="container pt-3 pt-md-4">
        <div className="row">
          <div className="col-md-8 col-12 mx-auto text-center">
            <h2 className="font-weight-bold text-dark">
              {currentEvent?.event_details?.EN?.event_name}
            </h2>
            <p className="text-dark mb-1">
              {moment(
                convertSecondstoMilliSeconds(
                  currentEvent?.event_details?.start_date?.seconds
                )
              ).format("DD MMMM YYYY")}
            </p>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-md-8 col-12 mx-auto bg-transparent w-100">
            <Card
              className="rounded-lg bg-white shadow"
              style={{ position: "sticky", top: 60 }}
            >
              <Card.Body>
                <div className="mb-3">
                  <Formik
                    validationSchema={RegisterVaildationSchema}
                    initialValues={{
                      firstName: userDetails?.displayName?.split(" ")[0],
                      lastName: userDetails?.displayName?.replace(
                        `${userDetails?.displayName?.split(" ")[0]} `,
                        ""
                      ),
                      email: userDetails?.email,
                      phone: "",
                      timestamp: new Date(),
                      alreadyExists: false,
                    }}
                    onSubmit={(values, { resetForm }) => {
                      continueWithNewEmail(resetForm, values);
                    }}
                  >
                    {({ errors, touched, isValidating, values }) => (
                      <Form>
                        <div className="">
                          <div>
                            <p className="text-dark text-center blockquote mb-2">
                              Register for the event. <br />
                              Simply fill out this form to sign up
                            </p>
                          </div>
                          <div className="form-label-group mt-3">
                            <label htmlFor="firstName" className="mb-1">
                              First name
                            </label>
                            <Field
                              name="firstName"
                              id="firstName"
                              value={userDetails?.displayName?.split(" ")[0]}
                              disabled={true}
                              type="text"
                              placeholder="First name"
                              className="form-control"
                            ></Field>

                            {errors.firstName && touched.firstName ? (
                              <div className="text-danger ml-2">
                                {errors.firstName}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-label-group mt-3">
                            <label htmlFor="lsstName" className="mb-1">
                              Last name
                            </label>
                            <Field
                              name="lastName"
                              id="lastName"
                              value={userDetails?.displayName?.replace(
                                `${userDetails?.displayName?.split(" ")[0]} `,
                                ""
                              )}
                              disabled={true}
                              type="text"
                              placeholder="First name"
                              className="form-control"
                            ></Field>

                            {errors.lastName && touched.lastName ? (
                              <div className="text-danger ml-2">
                                {errors.lastName}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-label-group mt-3">
                            <label htmlFor="phone" className="mb-1">
                              Phone number{" "}
                              <span className="text-muted small">
                                (optional)
                              </span>
                            </label>
                            <Field
                              name="phone"
                              id="phone"
                              type="tel"
                              placeholder="Phone number"
                              className="form-control"
                              value={userDetails?.phoneNumber}
                            ></Field>

                            {errors.phone && touched.phone ? (
                              <div className="text-danger ml-2">
                                {errors.phone}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-label-group  mt-3">
                            <label htmlFor="email" className="mb-1">
                              Email address
                            </label>
                            <Field
                              name="email"
                              id="email"
                              type="email"
                              value={userDetails?.email}
                              disabled={true}
                              placeholder="Email"
                              className="form-control"
                            ></Field>

                            {errors.email && touched.email ? (
                              <div className="text-danger ml-2">
                                {errors.email}
                              </div>
                            ) : null}
                          </div>

                          <div className="mt-2">
                            <input
                              type="checkbox"
                              id="t_n_c"
                              name="t_n_c"
                              value="Boat"
                              required={true}
                              className="mr-1"
                            />
                            <label for="t_n_c">
                              I agree to igesia's{" "}
                              <a target="_blank" href={`https://${PROD_HOSTED_BASE_URL}/terms`}>
                                Terms & Conditions
                              </a>{" "}
                              and{" "}
                              <a
                                target="_blank"
                                href={`https://${PROD_HOSTED_BASE_URL}/privacy`}
                              >
                                Privacy Policy
                              </a>
                            </label>
                          </div>
                        </div>

                        <div className="mt-3">
                          <div className="d-flex">
                            <button
                              type="submit"
                              className="btn btn-primary flex-grow-1"
                            >
                              {signing ? (
                                <Spinner animation="border" />
                              ) : (
                                "Register"
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="mt-3 d-flex">
                          <p className="align-items-center mb-0">
                            Not{" "}
                            <b>
                              {userDetails?.displayName} ({userDetails?.email})
                            </b>
                            ?{" "}
                            <a
                              className="text-primary"
                              style={{ cursor: "pointer" }}
                              onClick={async () => {
                                await firebase.auth().signOut();

                                toast.dark("Logged out", {
                                  position: toast.POSITION.TOP_CENTER,
                                  hideProgressBar: true,
                                  autoClose: 3000,
                                });
                              }}
                            >
                              Logout
                            </a>
                          </p>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      {/* Event cover ends */}
    </div>
  );
}

export default connect(
  (state) => ({ currentLanguage: state.currentLanguage }),
  { changeLanguage }
)(EventFillDetails);
